import { appConfig } from '../config/config';
import { Path } from '../models/interfaces';

export interface ITrackService {
  getTracks: () => Promise<{ [id: string]: Path }>;
  saveTrack: (trackId: string, track: Path) => Promise<Path>;
}

export class IndexedDBTrackService implements ITrackService {
  storeName: string;
  constructor() {
    this.storeName = appConfig().localStoragePathsPrefix;
  }

  async getTracks(): Promise<{ [id: string]: Path }> {
    return new Promise((resolve) => {
      const request = indexedDB.open(this.storeName, 1);
      request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
        const database = (event.target as IDBOpenDBRequest).result;
        if (
          !database.objectStoreNames.contains(this.storeName)
        ) {
          database.createObjectStore(this.storeName);
        }
      };

      request.onsuccess = (_) => {
        const database = request.result;
        const transaction = database.transaction(
          this.storeName,
          'readonly'
        );
        const objectStore = transaction.objectStore(
          this.storeName
        );
        const results: { [id: string]: Path } = {};
        const getTrackRequest = objectStore.openCursor();
        getTrackRequest.onsuccess = (event: Event) => {
          const cursor = (event.target as IDBRequest<IDBCursorWithValue>)
            .result;
          if (cursor) {
            results[cursor.key as string] = cursor.value as Path;
            cursor.continue();
          } else {
            resolve(results);
          }
        };
      };
    });
  }
  async saveTrack(trackId: string, track: Path): Promise<Path> {
    return new Promise((resolve) => {
      const request = indexedDB.open(this.storeName, 1);
      request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
        const database = (event.target as IDBOpenDBRequest).result;
        if (
          !database.objectStoreNames.contains(this.storeName)
        ) {
          database.createObjectStore(this.storeName);
        }
      };
      request.onsuccess = (_) => {
        const database = request.result;
        const transaction = database.transaction(
          this.storeName,
          'readwrite'
        );
        const objectStore = transaction.objectStore(
          this.storeName
        );
        objectStore.put(track, trackId);
        resolve(track);
      };
    });
  }
}

export class LocalStorageTrackService implements ITrackService {
  async getTracks(): Promise<{ [id: string]: Path }> {
    const localStoragePrefix = appConfig().localStoragePathsPrefix;
    const tracks: { [trackId: string]: Path } = {};
    for (let index = 0; index < localStorage.length; index++) {
      const key = localStorage.key(index);
      if (key?.startsWith(appConfig().localStoragePathsPrefix)) {
        tracks[key.split(`${localStoragePrefix}`)[1]] = JSON.parse(
          localStorage.getItem(key) as string
        ) as Path;
      }
    }
    return tracks;
  }
  async saveTrack(trackId: string, track: Path): Promise<Path> {
    localStorage.setItem(trackId, JSON.stringify(track));
    return track;
  }
}
